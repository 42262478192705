@import "styles/utils/mixins";

.grey {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 30%;
    background-color: #efeff3;
  }
}

.light {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 30%;
    background-color: #fff;
  }
}

.text-wrap {
  position: relative;
  width: 45%;
  margin-left: auto;
}

.text {
  position: absolute;
  top: 50px;
  width: 375px;
}

.title {
  margin-bottom: 20px;
}

.blocks {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: -30px;
}

.subscription {
  margin: 30px;
  display: flex;
  align-items: flex-end;
}

@include small-screen {
  .grey {
    &::before {
      height: 25%;
    }
  }

  .text-wrap {
    width: 100%;
    margin-left: 0;
    margin-bottom: 25px;
  }

  .text {
    position: relative;
    top: auto;
    width: 100%;
  }

  .blocks {
    grid-template-columns: 1fr;
    margin: 0;
  }

  .subscription {
    margin: 0;
  }
}
