@import "styles/utils/mixins";

.banner {
  position: relative;
  background-color: #18162c;
  padding: 60px 70px;
  margin: 30px;
  min-height: 400px;

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(24 22 44 / 30%) 18.98%,
      rgba(24 22 44 / 80%) 74.99%
    );
    z-index: 1;
    pointer-events: none;
  }

  &:nth-child(2) {
    transform: translate(0, 50%);
  }
}

.content {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 2;
}

.title {
  margin-bottom: 25px;
  position: relative;
  max-width: min-content;
}

.caption {
  max-width: 70%;
}

.image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.arrow {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  border: 1px solid rgba(255 255 255 / 60%);
  z-index: 3;
  transform: translate(50%, -50%) translateZ(0) scale(1);

  span {
    width: 24px;
    height: 24px;

    path:first-of-type {
      stroke: #fff;
    }

    path:last-of-type {
      fill: #fff;
    }
  }

  &:hover {
    transform: translate(50%, -50%) translateZ(0) scale(1.05);
  }
}

@include small-screen {
  .banner {
    margin: 0 0 30px;
    padding: 30px;
    min-height: 210px;

    &:nth-child(2) {
      transform: translate(0, 0);
    }
  }

  .title {
    margin-bottom: 6px;
  }

  .caption {
    max-width: 90%;
  }

  .arrow {
    width: 90px;
    height: 90px;
    transform: translate(75%, -50%) translateZ(0) scale(1);

    span {
      width: 16px;
      height: 16px;
    }
  }
}
